import React, { useEffect, useState } from "react";
import * as OurMembersAndTestimonialsApi from "../service/OurMembersAndTestimonialsApi";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";

const TeamMembersCard = () => {
  const [members, setMembers] = useState([])
  useEffect(()=>{
    OurMembersAndTestimonialsApi.getAllTeamMembers().then(membrs=> setMembers(membrs))
  }, [])

  return (
    <div>
      {members.map((member) => (
        <div key={member.id} className="team-member">
          <div className="member-image">
            <img src={member.image} alt="" />
          </div>
          <div className="name-and-designation">
            <div className="member-name">
              <h3>{member.name}</h3>
            </div>
            <div className="designation">{member.designation}</div>
          </div>
          <div className="social-links">
            <a target="_blank" href={member.socialLinks.linkedin}>
              <FaLinkedinIn />
            </a>
            <a target="_blank" href={member.socialLinks.facebook}>
              <FaFacebook />
            </a>
          </div>
          <div className="member-intro">
            <p>{member.details}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamMembersCard;
