import React, { useState } from 'react';
import './ChatBot.css'; // Import your CSS file
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useParams } from "react-router-dom";

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const params = useParams();
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSendMessage = () => {
    if (inputText.trim() !== '') {
      setMessages([...messages, { text: inputText, type: 'user' }]);
      setInputText('');
      // You can add logic here to generate a response from the chatbot
    }
  };

  return (
    <div className="chatbot-container">
      <h1>Hi {params.userName} how may i help you </h1>
      <div className="chat-messages">
        {messages.map((message, index) => (
          <ReactMarkdown key={index} className={`message ${message.type}`}>
            {message.text}
          </ReactMarkdown>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Type your message..."
          value={inputText}
          onChange={handleInputChange}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ChatBot;
