import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as CoursesApi from "../../service/CoursesApi";
import SearchAndPopularTags from "../../Atoms/SerachAndPopularTags";
import CoursePrograms from "../../Organism/Programs";
import "./Subjects.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Programs() {
  const [searchText, setSearchText] = useState("");
  const [courses, setCourses] = useState([]);
  const [populerShortcuts, setpopulerShortcuts] = useState([]);
  const [interest, setInterest] = useState("");

  useEffect(() => {
    CoursesApi.getAllCourses().then((cs) => setCourses(cs));
    CoursesApi.getPopulerShortcuts().then((shortcuts) =>
      setpopulerShortcuts(shortcuts)
    );
  }, []);

  useEffect(() => {
    CoursesApi.getAllCourses("", interest).then((cs) => setCourses(cs));
  }, [interest]);

  const searchCourse = (e) => {
    e.preventDefault();
    console.log("search " + searchText + " interest " + interest);
    CoursesApi.getAllCourses(searchText, interest).then((cs) => setCourses(cs));
  };

  return (
    <div className="program-container">
      <SearchAndPopularTags
        props={{
          interest,
          populerShortcuts,
          setInterest,
          searchText,
          setSearchText,
          searchEvent: searchCourse,
        }}
      />

      <div className="programGrid">
        <CoursePrograms courses={courses} />
      </div>
    </div>
  );
}

function Subjects(props) {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [loginUser, setLoginUser] = useState(true);

  useEffect(() => {
    CoursesApi.getAllCourses("", "").then((courses) =>
      setCourses(courses)
    );
  }, []);

  if (courses.length != 0) {
    return (
      <div className="subjects-container">
        <div className="subjects-and-add-courses">
          <div className="subjects-heading">
            <h4>Subjects</h4>
          </div>
          <div className="add-courses">
            <i class="fa-solid fa-plus"></i>
            <p>Add Courses</p>
          </div>
        </div>

        <h3>Enrolled Courses</h3>
        <div className="dash-subject-progress-container">
          {courses.map((course) => (
            <div style={{ margin: "1em" }} className="subject-item">
              <div className="course-name">
                <h6>Courses</h6>
                <h2>Python Programming</h2>

                <Link className="view-all-chapters">
                  <p>View all chapters</p>
                  <i class="fa-solid fa-greater-than"></i>
                </Link>
              </div>
              <div className="progress-bar-and-course-container">
                <div className="progress-circle-one"></div>
                <div className="progress-circle-two"></div>
                <div className="course-title-and-actions">
                  <div style={{ width: "40%" }}>
                    <h5 style={{ margin: 0 }}>Chapter-1</h5>
                    <p>{course.title}</p>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/dashboard/programs/" + course.code + "/learn")
                    }
                  >
                    Go to Course{" "}
                  </button>
                  <button
                    onClick={() =>
                      navigate("/dashboard/programs/" + course.code)
                    }
                  >
                    View Course
                  </button>
                </div>

                <div className="progress-bar-container">
                  <div className="progress-bar"></div>
                </div>

                {/* <Slider
                    disabled
                    defaultValue={50}
                    aria-label="Disabled slider"
                  /> */}
              </div>
            </div>
          ))}
        </div>

        <div className="discover-courses">
          <div className="discover-heading">
            <h2>Discover tailored course recommendations just for you!</h2>
          </div>
          <div className="discover-courses-container">
            <CoursePrograms courses={courses.slice(0, 3)} />
            {/* <Programs /> */}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="subjects-container">
        <div className="subjects-and-add-courses">
          <div className="subjects-heading">
            <h4>Subjects</h4>
          </div>
          <div className="add-courses">
            <i class="fa-solid fa-plus"></i>
            <p>Add Courses</p>
          </div>
        </div>
        <div className="discover-courses-container">
          <CoursePrograms courses={courses.slice(0, 3)} />
          {/* <Programs /> */}
        </div>
        <Programs />
      </div>
    );
  }
}

export default Subjects;
