import React, { useState } from "react";
import "./Header.css"; // Import the CSS file for styling
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { HEADERS } from "../../Constants/Constants";

const Header = (props) => {
  const params = useParams();
  const history = useNavigate();
  const [isNavOpen, setIsNavOpen] = useState(false);

  // const [buttonClicked, setButton] = useState("/home");

  const location = useLocation();
  const pathLocation = [
    "/login",
    "/register",
    "/undermaintainance",
    "/mobile-maintainance",
  ];

  const loginAndRegister = pathLocation.includes(location.pathname);

  const isHomePage = location.pathname === "/home";

  const handleRespNav = () => {
    // console.log(navOpen.current);

    setIsNavOpen(!isNavOpen);
  };

  const handleNavItemClick = (path) => {
    // Close the mobile navigation
    setIsNavOpen(false);

    // Navigate to the selected route
    history(path);
  };

  if (!loginAndRegister)
    return (
      <header className="header">
        <div className="logo">
          <Link to={"/home"}>
            <img src="../images/Logo.svg" alt="byte-coder-logo" />
          </Link>
        </div>
        <nav
          className={isNavOpen ? "mobile-nav-links" : "nav-links"}
          // onClick={handleRespNav}
        >
          <ul>
            {HEADERS.map((header) => (
              <li key={header.tag}>
                <Link
                  to={header.path}
                  className={
                    (isHomePage && header.path === "/home") ||
                    (!isHomePage && location.pathname === header.path)
                      ? "Clicked visited-point"
                      : "NotClicked"
                  }
                  onClick={() => handleNavItemClick(header.path)}
                >
                  {header.tag}
                </Link>
              </li>
            ))}
          </ul>

          <div className="mobile-view " onClick={handleRespNav}>
            <i
              class={` ${isNavOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"}`}
            ></i>
          </div>
        </nav>
      </header>
    );
};

export default Header;
