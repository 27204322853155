import React from "react";
import Layouts from "../LandingPages/Layout/Layouts";
import { Link, useNavigate } from "react-router-dom";
import "./Register.css";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { update } from "react-spring";
import { useEffect } from "react";
import {registerNewUser} from '../service/LoginRegistrationApi'

const Register = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [mobile, setMobile] = useState("");

  const [studentData, setStudentData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    // This code will run when studentData changes
    if (studentData) {
      localStorage.setItem("student-data", JSON.stringify(studentData));
    }
  }, [studentData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      userName == "" &&
      userEmail == "" &&
      collegeName == "" &&
      mobile == ""
    ) {
      toast.error("Please fill the credentials!!!");
    } else if (
      userName == "" ||
      userEmail == "" ||
      collegeName == "" ||
      mobile == ""
    ) {
      toast.error("All fields required");
    }
    else {
     
      const updatedData = {
        name: userEmail,
        email: collegeName,
        phone: mobile,
        password:"test@123"
      };

      setStudentData(updatedData);

      console.log(studentData, updatedData);
      registerNewUser(updatedData)
      toast.success("you are successfully register, please login.");
      setTimeout(() => {
        navigate("/success-registration");
      }, 2000);
    }
  };

  return (
    <div className="register-container">
      <Layouts />

      <div className="circle-one"></div>

      <div className="register-right-container">
        {/* <div className="circle-two"></div> */}
        <div className="register-form-heading">
          <div>
            <h3>Sign Up</h3>
            <p className="login-custom-underline">
              Join Us and start your unstoppable journey!
            </p>
          </div>

          <div className="mobile-svg-image">
            <img src="/images/login-page-image.svg" alt="" />
          </div>
        </div>

        <div className="register-types">
          <form className="register-form">
            <div className="user-email">
              <label>Full Name <span className="asterisk">*</span></label>
              <input
                value={userName}
                type="text"
                placeholder="Your Name"
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="user-email">
              <label>Email <span className="asterisk">*</span></label>
              <input
                value={userEmail}
                type="email"
                placeholder="example123@gmail.com"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="user-college">
              <label>College Name <span className="asterisk">*</span></label>
              <input
                value={collegeName}
                type="text"
                placeholder=""
                onChange={(e) => setCollegeName(e.target.value)}
              />
            </div>

            <div className="user-phone">
              <label>Mobile Number <span className="asterisk">*</span></label>
              <input
                value={mobile}
                type="number"
                placeholder=""
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className="user-experience">
              <label className="experience-heading">Experience</label>
              <div className="radio-area">
                <div className="fresher radio-btn">
                  <input id="fresher" type="radio" name="experience" />
                  <label htmlFor="fresher">I'm Fresher</label>
                </div>
                <div className="experience radio-btn">
                  <input id="exp" type="radio" name="experience" />
                  <label htmlFor="exp">I'm Experienced</label>
                </div>
              </div>
            </div>

            <div className="term-and-condiiton">
              <p>
                By registering you agree to the{" "}
                <Link style={{ color: "red" }}>terms and conditions </Link>
                and <Link style={{ color: "red" }}>privacy policy</Link> of
                Bytecoder.{" "}
              </p>

              <input type="button" value="Register" onClick={handleSubmit} />
              <ToastContainer />
            </div>
          </form>

          <div className="vertical-line"></div>

          <div className="or-and-lines">
            <div className="line1"></div>
            <div>
              <p>or</p>
            </div>
            <div className="line2"></div>
          </div>

          <div className="other-login-auth">
            <p>Sign In With Google</p>

            <Link>
              <div className="google-auth">
                <img src="/images/google.svg" alt="" />
                <span>
                  <p>Sign In with Google</p>
                </span>
              </div>
            </Link>
          </div>
        </div>

        {/* <div className="term-and-condiiton">
          <p>
            By registering you agree to the{" "}
            <Link style={{ color: "red" }}>terms and conditions </Link>
            and <Link style={{ color: "red" }}>privacy policy</Link> of
            Bytecoder.{" "}
          </p>

          <button onClick={handleSubmit}>Register </button>
          <ToastContainer />
        </div> */}

        <p>
          Already have an Account? <Link to={"/login"}>Login Here</Link>{" "}
        </p>
      </div>
    </div>
  );
};

export default Register;
