import React, { useEffect, useState } from "react";
import "./QuickNotes.css";
import * as CoursesApi from "../../../service/CoursesApi";
import {
  TextField,
  Button,
  Paper,
  List,
  ListItem,
  OutlinedInput,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { SelectButton, ChatBar, TopicsListBar } from "../../../Atoms/Buttons";
import { QUICK_LINKS } from "../../../Constants/Constants";
import CustomFormField from "../../../Molecule/CustomFormField";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const tempTopicDetail = {
  topic: "Array",
  messages: [
    {
      id: 0,
      type: "quick-summary",
      input: "Summmary",
      response: "Arrays In data structure and algorithms",
      isBookMarked: false,
    },
    {
      id: 1,
      type: "example",
      input: "here is your own notes",
      response: "nice work",
      isBookMarked: true,
    },
    {
      id: 1,
      type: "question",
      input: "here is your own notes",
      response: "nice work",
      isBookMarked: true,
    },
  ],
};

function SuggestedVideos(props) {
  let examples = CoursesApi.getSuggestedVideos(props.subject, props.topic);
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {examples.map((example, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Item>
                <p>{example.input}</p>
                <video src={example.response} type="video/mp4" controls>
                  {" "}
                </video>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

function SuggestedExamples(props) {
  let suggestExamples = CoursesApi.getSuggestedExamples(
    props.subject,
    props.topic
  );
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {suggestExamples.map((example, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Item onClick={() => props.handleClick(example)}>
                {example.input} The useEffect Hook allows you to perform side
                effects in your components.
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

const QuickOptions = (props) => {
  console.log(props);
  if (props.selectedQuick === "Examples") {
    return (
      <SuggestedExamples
        subject={props.subject}
        topic={props.selectedTopic}
        handleClick={props.handleClick}
      ></SuggestedExamples>
    );
  } else if (props.selectedQuick === "Videos") {
    return (
      <SuggestedVideos subject={props.subject} topic={props.selectedTopic} />
    );
  }
  return <div></div>;
};

function QuickNotes(props) {
  const [selectedQuick, setSelectedQuick] = useState("");
  const [selectedTopic, setselectedTopic] = useState("Array");
  const [inputText, setinputText] = useState("");
  const [topicDetails, setTopicDetail] = useState(tempTopicDetail);
  const [topicslist, setTopicsList] = useState([]);

  const handleClick = (message) => {
    setTopicDetail({
      messages: [...topicDetails.messages, message],
    });
  };

  const handleSendMessage = (id) => {
    console.log(id);
    if (inputText.trim() !== "") {
      console.log(inputText);
      setinputText("");
      setTopicDetail({
        messages: [
          ...topicDetails.messages,
          {
            input: inputText,
            response:
              "Of course! How can I assist you with your test today? Please provide more details or questions you'd like to ask, and I'll do my best to help. ",
            id,
            type: "Chat",
          },
        ],
      });
    }
  };

  useEffect(() => {
    console.log("init ");

    console.log(props.subject);
    let topcs = CoursesApi.getTopics(props.subject);
    setTopicsList(topcs);
    setselectedTopic(topcs[0]);
    console.log(topcs);
  }, [props]);

  useEffect(() => {
    console.log("setting " + selectedTopic);
    let topicDetail = CoursesApi.getSubjectQuickNotes(
      selectedTopic,
      props.subject
    );
    console.log(topicDetail);
    setTopicDetail(topicDetail);
  }, [selectedTopic]);

  useEffect(() => {
    console.log("setting " + selectedQuick);
    // let topicDetail = CoursesApi.getSubjectQuickNotes(selectedTopic, props.subject)
    // console.log(topicDetail)
    // setTopicDetail(topicDetails)
  }, [selectedQuick]);

  return (
    <div className="quick-notes">
      <Paper style={{ width: "100%", padding: "16px", display: "flex", backgroundColor:"lightgrey" }}>
        <div>
          <TopicsListBar
            topicslist={topicslist}
            setselectedTopic={setselectedTopic}
            selectedTopic={selectedTopic}
          />
        </div>
        <div
          className="subject-notes"
          style={{
            width:"60vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "top",
          }}
        >
          <div>
            <div className="subject-notes-list" >
              {topicDetails.messages.map((message) => (
                <CustomFormField question={message} />
              ))}
            </div>
          </div>

          <QuickOptions
            subject={props.subject}
            selectedQuick={selectedQuick}
            handleClick={handleClick}
          />

          <div className="quick-link">
            {QUICK_LINKS.map((link) => (
              <SelectButton
                link={link}
                handleClick={(link) => setSelectedQuick(link)}
              ></SelectButton>
            ))}
          </div>

          <ChatBar
            inputText={inputText}
            setinputText={setinputText}
            handleSendMessage={handleSendMessage}
          />
        </div>
      </Paper>
    </div>
  );
}

export default QuickNotes;
