import React from "react";
import ServiceCardDetails from "../../Organism/Services";
import "../ServicesPage/ServicesPage.css";

export default function Services() {
  return (
    <div className="services-conatiner">
      <div className="services-details">
        <div className="service-head">
          <h2>Our Services</h2>
          <p style={{ textAlign: "center" }}>
            Elevating Your Journey from Education to Employment
          </p>
          <div className="student-img">
            <img src="/images/student.svg" alt="" />
          </div>
        </div>
      </div>
      <ServiceCardDetails />
    </div>
  );
}
