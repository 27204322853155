import React, { useEffect, useState } from "react";
import "./Login.css"; // Import the CSS file
import { Link, useNavigate } from "react-router-dom";
import { Button, Form } from "antd";
import Layouts from "../LandingPages/Layout/Layouts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {loginUser} from '../service/LoginRegistrationApi'

export default function Login(props) {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isMobile, setMobile] = useState(false); // here we are checking that is mobile vide is active or not
  const navigate = useNavigate();

  const checkIsMobile = () => {
    // Determine whether it's mobile or desktop based on screen size
    setMobile(window.innerWidth <= 850);
  };

  useEffect(() => {
    // Check initially when the component mounts
    checkIsMobile();

    // Add event listener to listen for window resize events
    window.addEventListener("resize", checkIsMobile);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

 

  const handleSubmit = (e) => {
    // e.preventDefault();

    console.log("clicked!!!!!");

    if (userEmail == "" && password == "") {
      toast.error("please fill the credentials!!");
    } else if (userEmail == "") {
      toast.error("please enter the email");
    } else if (password == "") {
      toast.error("please enter the password");
    } else {
    const updatedData = {
      email: userEmail,
      password: password,
    };

    loginUser(updatedData).then((response)=>{
      console.log(response)
      toast.success("you are successfully logged in.");
      setTimeout(() => {
        if (isMobile) {
          navigate("/mobile-maintainance");
        } else {
          navigate("/undermaintainance");
        }
      }, 1000);
    })
   
  }

  };

  return (
    <div className="login-container">
      <Layouts />

      <div className="circle-one"></div>
      <div className="circle-two"></div>
      <div className="login-right-container">
        <div className="login-form-heading">
          <div>
            <h3>Log In</h3>
            <p className="login-custom-underline">
              Securely access your BYTECODER account.
            </p>
          </div>

          <div className="mobile-svg-image">
            <img src="/images/login-page-image.svg" alt="" />
          </div>
        </div>

        <div className="login-types">
          <form className="login-form">
            <div className="user-email">
              <label>
                Email <span className="asterisk">*</span>
              </label>
              <input
                value={userEmail}
                type="email"
                placeholder="example123@gmail.com"
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </div>
            <div className="user-password">
              <label>
                Password <span className="asterisk">*</span>
              </label>
              <input
                value={password}
                type="password"
                placeholder=""
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="forget-and-login">
              <Link> Forget Password?</Link>

              <input
                type="button"
                placeholder="Login"
                value="Login"
                onClick={handleSubmit}
              />
              <ToastContainer />
            </div>
          </form>

          <div className="vertical-line"></div>

          <div className="or-and-lines">
            <div className="line1"></div>
            <div>
              <p>or</p>
            </div>
            <div className="line2"></div>
          </div>

          <div className="other-login-auth">
            <p className="sign-in-google">Sign In With Google</p>

            <Link>
              <div className="google-auth">
                <img src="/images/google.svg" alt="" />
                <span>
                  <p>Sign In with Google</p>
                </span>
              </div>
            </Link>
          </div>
        </div>
        <p>
          New To ByteCoder <Link to={"/register"}>Sign Up Here</Link>{" "}
        </p>
      </div>
    </div>
  );
}

// -============================================================

// // ... (rest of the component code remains the same)
// const [formData, setFormData] = useState({
//   email: '',
//   password: '',
// });
// const navigate = useNavigate();
// // Handler for form input changes
// const handleInputChange = (e) => {
//   const { name, value } = e.target;
//   setFormData({
//     ...formData,
//     [name]: value,
//   });
// };

// // Handler for login submission
// const handleLogin = async (e) => {
//   e.preventDefault();
//   console.log(formData)
//   props.setLoggedin(true)
//   navigate('/dashboard/subjects');
//   window.location.reload();

// };

// const [googleSignupUrl, setGoogleSignupUrl] = useState('');

// const handleGoogleSignup = async () => {
//   try {
//     const response = await fetch('http://0.0.0.0:8000/api/v1/google-signup-url'); // Replace with your server URL
//     const data = await response.json();
//     console.log(data)

//     // Extract the URL from the response data
//     const url = data.url;
//     setGoogleSignupUrl(url);

//     // Open a new tab with the URL
//     window.open(url, '_blank');
//   } catch (error) {
//     console.error('Error fetching Google sign-up URL:', error);
//   }
// };

// return (
//   <div className="login-container"> {/* Add the container class */}
//     <h2>Login</h2>
//     <form className="login-form" onSubmit={handleLogin}> {/* Add the form class */}
//       <div>
//         <label htmlFor="email">Email:</label>
//         <input
//           type="email"
//           id="email"
//           name="email"
//           value={formData.email}
//           onChange={handleInputChange}
//           required
//         />
//       </div>
//       <div>
//         <label htmlFor="password">Password:</label>
//         <input
//           type="password"
//           id="password"
//           name="password"
//           value={formData.password}
//           onChange={handleInputChange}
//           required
//         />
//       </div>

//       <button type="submit" > Login</button>

//     </form>
//     <div className="google-login">
//     <button onClick={handleGoogleSignup}>Sign In with Google</button>
//     {googleSignupUrl && (
//       <p>
//         Opening Google sign-up URL: <a href={googleSignupUrl} target="_blank" rel="noopener noreferrer">{googleSignupUrl}</a>
//       </p>
//     )}
//   </div>
//   </div>
// );
