// CandidateForm.js

import React, { useState } from 'react';
import './CandidateForm.css'
import UserProfile from './UserProfile'
import { useNavigate } from 'react-router-dom';



function CandidateForm()  {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    experience: '',
    profileSummary: '',
  });
  const navigate = useNavigate()
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const logoutUser = () => {
    localStorage.setItem("loggedin", "false" )
    navigate('/');
    window.location.reload();
  };

  return (
    <div className="candidate-form-container"> {/* Apply the container class */}
      <h2>Candidate Profile</h2>
        <button>Share</button>
        <button>edit profile</button>
        <button onClick={()=> logoutUser()}>logout</button>
      <UserProfile></UserProfile>
    </div>
  );
};

export default CandidateForm;
