import React from "react";
import { TypeAnimation } from "react-type-animation";

export const TypeWriterComponent = (props) => {
  const content = [
    // Same substring at the start will only be typed out once, initially
    // "welcome to byteGPT",
    1000, // wait 1s before replacing "Mice" with "Hamsters"
    props.content,
  ];

  return (
    <TypeAnimation
      sequence={content}
      wrapper="span"
      speed={50}
      style={{ fontSize: "1em", display: "inline-block" }}
      repeat={0}
    />
  );
};
