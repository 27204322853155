import React from "react";
import "./App.css";
import Dashboard from "./Dashboard/Dashboard";
import LandingPage from "./LandingPages/LandingPage";

function App() {
  const setLoggedin = () => {
    localStorage.setItem("loggedin", "true");
    localStorage.setItem("token", "c1");
    console.log(localStorage.getItem("loggedin"));
    console.log(JSON.parse(localStorage.getItem("loggedin")));
  };
  let login = JSON.parse(localStorage.getItem("loggedin"));
  setLoggedin()
  
  if (true) {
    return (
        <LandingPage setLoggedin={setLoggedin} />
    );
  } 
  else {
    return < Dashboard />;
  }

}

export default App;
