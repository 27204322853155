
import * as Congs from './Configurations'

export async function registerNewUser(userData)  {
    console.log("fetching shortcuts")

    const response = await fetch(Congs.NEW_REGISTRATION, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch((e) => console.log("Error " + e));

    const result = await response.json();
    console.log(result)  
  };

// export async function loginUser(userData)  {
//     console.log("logging user")
//     try{
//         const response = await fetch(Congs.Login_USER, {
//             method: 'POST',
//             body: JSON.stringify(userData),
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           })
//           const result = await response.json();
//           console.log(result)  

//     }catch(error){
//         console.log(error) 
//     }

//   };


  export function loginUser(userData)  {
    console.log("logging user")

    return fetch(Congs.Login_USER, {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res)=>res.json()).catch((e)=>{
        // toast.error("Please enter right credentials.");
        console.log("error")
      })
  };