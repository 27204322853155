import React, { useEffect } from "react";
import "./CSS/BlogCard.css";
import BlogsCardDetails from "../LandingPages/BlogsCardPage/BlogsCardDetails";
import {
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Avatar,
  CardActions,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import {
  Favorite as FavoriteIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  Search as SearchIcon,
  ClassOutlined,
} from "@mui/icons-material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";

export function BlogCard(props) {
  const selectedPost = props.selectedPost;

  // Use the effect to scroll to the top when the component opens
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [selectedPost]);
  let blog = props.blog;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blogs/${blog.id}`);
  };
  return (
    <div key={blog.id} className="blog-cards" onClick={handleClick}>
      {/* <Link to={`blogs/${blog.id}`}  className="blog-links"> */}

      <div className="blog-square-img">
            <img src='/images/temp/common.png' alt="/images/temp/default.png"  />
        
      </div>

      <div className="blog-data">
        <div className="blog-title">
          <p>{blog.title}</p>
        </div>

        <div className="blog-data-desc">
          <p>{blog.description}</p>
        </div>

        <div className="views-comments">
          <div>
            <p>{blog.views} views</p>
          </div>
          <div>
            <p>{blog.commentsCount} comments</p>
          </div>
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
}

export function ReadBlog(props) {
  const selectedPost = props.selectedPost;

  return (
    <div>
      <Dialog
        open={selectedPost !== null}
        onClose={() => props.handleClose()}
        maxWidth="md"
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.handleClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Sound
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => props.handleClose()}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>

        {selectedPost && (
          <>
            <CardHeader
              avatar={<Avatar aria-label="Author">A</Avatar>}
              title={selectedPost.title}
              subheader="3 hours ago"
            />
            <CardMedia
              component="img"
              height="200"
              image={selectedPost.image}
              alt="Post Image"
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {selectedPost.content}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="Like">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="Comment">
                <ChatBubbleOutlineIcon />
              </IconButton>
            </CardActions>
            <DialogContent>
              <DialogContentText>
                {/* Display comments or questions here */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <TextField
                label="Add Comment or Question"
                variant="outlined"
                fullWidth
              />
              <Button variant="contained" color="primary">
                Add
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
