import React from "react";
import "./MyDashboard.css";

const MyDashboard = () => {
  return (
    <div className="my-dashboard">
      <div className="dashboard-intro">
        <h2>WELCOME TO BYTECODER!</h2>
        <p>
          Welcome to Bytecode – Where Coding Dreams Come to Life! 🚀 Ask AI
          anything, solve problems effortlessly, and embark on your coding
          journey with our expert guidance. Get ready to unlock your full coding
          potential and let Bytecode be your compass on this exciting adventure!
        </p>
        <div className="start-learning">Start Learning</div>
      </div>

      <div className="recommendation-and-input">
        <div className="recommendation">
          <p>How to Start Your DSA Journey at Bytecoder' for a Smooth Beginning.</p>
          <img src="/images/send.svg" alt="" />
        </div>


        <div className="input-and-send">
          <div className="searching">
          <input type="text" />
          <img src="/images/mic.svg" alt="" />
          </div>

          <div className="send">
            <img src="/images/send.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;
