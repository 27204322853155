import PersonIcon from "@mui/icons-material/Person";
import SubjectIcon from "@mui/icons-material/Subject";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkIcon from "@mui/icons-material/Work";
import { Dashboard } from "@mui/icons-material";

export const COLOR_CODES = { primary: "#0F547E", secondary: "#28B2B6" };

export const Heading_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export const TEXT_CCS = { primary: "#0F547E", secondary: "#28B2B6" };

export function getButton_css(tab, selectedIcon) {
  return {
    color: tab.tag === selectedIcon ? "white" : "black",
    backgroundColor: tab.tag === selectedIcon ? "green" : "white",
  };
}

export const HEADERS = [
  { tag: "Home", path: "/home" },
  { tag: "Services", path: "/services" },
  { tag: "Programs", path: "/programs" },
  { tag: "Blog", path: "/blogs" },
  { tag: "Careers", path: "/careers" },
  { tag: "About Us", path: "/about-us" },
  { tag: "login", path: "/login" },
  // {tag:"chat", path:"/chat"},
];

export const FOOTERS = [
  { tag: "Home", path: "/home" },
  { tag: "Services", path: "/services" },
  { tag: "Programs", path: "/programs" },
  { tag: "About Us", path: "/about-us" },
  { tag: "Blog", path: "/blogs" },
  { tag: "Careers", path: "/careers" },
];

export const DASHBOARD_TABS = [
  {tag: "Dashboard", path:"/dashboard", icon:<Dashboard />},
  { tag: "Subjects", path: "/dashboard/subjects", icon: <SubjectIcon /> },
  // { tag: "Other", path: "/dashboard/others", icon: <AcUnitIcon /> },
  { tag: "Blogs", path: "/dashboard/blogs", icon: <LibraryBooksIcon /> },
  // {tag:"Calender", path: "/dashboard/calender",icon: <CalendarMonthIcon/>},
  {tag:"Jobs", path: "/dashboard/jobs",icon: <WorkIcon/>},
  { tag: "profile", path: "/dashboard/profile", icon: <PersonIcon /> },
];

export const SUBJECT_SIDE_NAV = [
  { tag: "Sessions" },
  { tag: "Quick-notes" },
  { tag: "Practice" },
  { tag: "Assignment" },
  {tag:"Progress"},
  // {tag:"Books"},
  // {tag:"Exam"},
];


export const QUICK_LINKS = ["Regenrate", "Examples", "Videos"];

export const Home_DIV3_SERVICE = [
  {
    page:"01",
    title: "Revolutionising Learning",
    description:
      "At the forefront of education transformation, we introduce a comprehensive approach that not only hones technical expertise but nurtures your holistic development. Our AI model prioritises a 360-degree enhancement, including soft skills, communication finesse, and other vital attributes.",
    imageUrl: "./images/lapi.svg",
  },
  {
    page:"02",
    title: "Personalised Learning Path",
    description:
      "Aligned with our mission to cater to your unique pace and preferences, our AI (byteGpt) generates a personalised learning curve. This trajectory adapts based on your progress, assignments, and problem-solving efforts. This dynamic learning path offers solutions to doubts, concise notes, illustrative examples, and relevant video suggestions – all dynamically tailored to enrich your understanding",
    imageUrl: "./images/lapi.svg",
  },
  {
    page:"03",
    title: "Guidance from Accomplished Mentors",
    description:
      "To foster your well-rounded development, we provide mentorship programs led by accomplished professionals from esteemed Fortune companies. These certified mentors offer personalised training and interview guidance, imparting real-world insights and experience..",
    imageUrl: "./images/lapi.svg",
  },
  {
    page:"04",
    title: "AI-Driven Skill Refinement",
    description:
      "Practice makes perfect, and with our AI-powered system, your practice is truly optimised. Expect assignments, practice problems, and feedback that align with your evolving skill level. Engage in live industry projects and real-world challenges, bridging the gap between academic learning and practical application.",
    imageUrl: "./images/hands.svg",
  },
  {
    page:"05",
    title: "Navigating Your Career Path",
    description:
      "Your journey doesn't culminate in learning; it leads to a career that suits your prowess. Our platform guides you to fitting job opportunities that resonate with your evolving skill set. As your proficiency advances, our job recommendations update, ensuring alignment with the ever-changing job landscape.",
    imageUrl: "./images/compass.svg",
  },
];


export const SERVICE_CARD_DETAILS = [
  {
    id: 1,
    title: "Talent Acquisition and Placement",
    number: "01",
    image: "/images/thinking.svg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
  {
    id: 2,
    title: "Comprehensive Mentorship",
    number: "02",
    image: "/images/meeting.svg",

    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
  {
    id: 3,
    title: "Industry Connections and Networking",
    number: "03",
    image: "/images/post-it.svg",

    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
  {
    id: 4,
    title: "Talent Acquisition and Placement",
    number: "04",
    image: "/images/interviewing.svg",
    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
  {
    id: 5,
    title: "Comprehensive Mentorship",
    number: "05",
    image: "/images/integerity.svg",

    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
  {
    id: 6,
    title: "Industry Connections and Networking",
    number: "06",
    image: "/images/working-hand.svg",

    description:
      "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
  },
];
