import React, { useEffect, useState } from "react";
import * as MembersService from "../service/OurMembersAndTestimonialsApi";
export default function TestimonialCard() {
  const [allReviews, setAllReviews] = useState([]);
  const [review, setReview] = useState({
    name: "Test ",
    imageUrl: "/images/testimonial-user.svg",
    position: "Software Engineer",
    companyName: "Tigar analytics",
    review:
      "I want to express my sincere gratitude and admiration for Bytecoder and the incredible impact it has had on my career. Bytecoder has been a game-changer in my software development processes and boosting overall my learning",
    videoUrl: "/images/testimonial-user.svg",
  });

  useEffect(() => {
    MembersService.getAllTestimonials().then((reviews) => {
      console.log(reviews);
      setReview(reviews[0]);
      setAllReviews(reviews);
    });
    console.log(review);
  }, []);
  function updateReview (rw){
    setReview(rw);

  }

  return (
    <div id="testimonial" className="testimonials">
      <div className="testimonial-heading">
        <h2 style={{ margin: 0 }}>Testimonials of our Folks</h2>
      </div>

      <div className="feedback-para">
        <p style={{ textAlign: "center" }}>
          Candid Insights and Valuable Feedback from Our Esteemed Community
          Members
        </p>
      </div>

      <div className="testimonial-user-data">
        <div className="user-image">
          <img src={review.imageUrl} alt="userImage" />
        </div>

        <div className="user-testimonial-edu">
          <div className="user-testimonial">
            <div className="quotes">
              <img src="/images/quotes.svg" alt="" />
              <img src="/images/quotes.svg" alt="" />
            </div>
            <p>{review.review}</p>
          </div>

          <div className="user-name-edu">
            <div className="user-name">
              <h4 style={{ margin: 0 }}>{review.name}</h4>
              <p className="user-position-and-company">
                {review.position} - {review.companyName}
              </p>
            </div>

            <div className="other-testimonials">
              {allReviews.map(rw=> <div onClick={()=> updateReview(rw)} className="other-testimonials-image" > <img src={rw.imageUrl} alt="" /></div>)}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
