import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as BlogsService from "../../service/BlogsApi";
import "../BlogsCardPage/BlogDetailsPage.css";
import ReactMarkdown from "react-markdown";
import { FaLinkedinIn } from "react-icons/fa";
import { BlogCard } from "../../Molecule/BlogsCard";
import { commentApiData } from "../../service/CommentApi";

const BlogDetailsPage = () => {
  const [blogData, setBlogData] = useState({paragraphs:[], reviews:[]});
  const blogId = useParams();
  const [like, setLike] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    BlogsService.getWebsiteBlogs("","").then((response) => {
      const filteredBlog = response.response.find(
        (blog) => blog.id == blogId.id
      );

      if (filteredBlog) {
        setBlogData(filteredBlog);
      } else {
        console.log("blog not found!!!");
      }
    });
    console.log("blogData", blogData);
  }, []);

  //   console.log(blogId.id);

  const handleLike = (count) => {
    if (like) {
      count++;
    } else {
      count--;
    }
    setLike(!like);
  };

  const hanldeCommentClick = () => {
    navigate("/register")
  }
  

  return (
    <div className="blog-details-container">
     
      <div className="blog-title-image-card">
        <div className="blog-title-card">
        <img src="../images/temp/verified.svg" alt="byte-coder-logo" />
          <h1>{blogData.title}</h1>
          <img src="../images/Logo.svg" alt="byte-coder-logo" />

        </div>
        <div className="blog-img">
        <img src='/images/temp/common.png' alt="../images/temp/default.png"  />

        </div>
        
      </div>

      <div className="blog-data-title">
        <h1>{blogData.title}</h1>
      </div>

      <div className="duration-and-action">
        <div className="date-and-read">
          <p>{blogData.publishedDate}</p>
        </div>

        <div className="like-and-share">
          <i
            class={like ? `fa-regular fa-heart` : `fa-solid fa-heart red`}
            onClick={handleLike}
          ></i>
          <i class="fa-solid fa-share"></i>
        </div>
      </div>

      <div className="blog-data-para">
        {blogData.paragraphs.map(paragraph=> <ReactMarkdown>{paragraph}</ReactMarkdown> )}
        
      </div>

      <div className="horizontal-line"></div>

      <div className="socials-and-comments-data">
        {/* <div className="socials">
          <i class="fa-brands fa-instagram"></i>
          <a href="https://www.linkedin.com/company/96441539/">
            <FaLinkedinIn />
          </a>
          <i class="fa-brands fa-twitter"></i>
          <i class="fa-brands fa-facebook"></i>
          <i class="fa-brands fa-whatsapp"></i>
        </div> */}

        <div className="view-comments-likes">
          <p>
            {blogData.views} views | {blogData.commentsCount} comments |{" "}
            {blogData.likes}{" "}
            <i
              className={like ? `fa-regular fa-heart` : `fa-solid fa-heart red`}
              onClick={handleLike}
            ></i>{" "}
          </p>
        </div>
      </div>

      <div className="horizontal-line"></div>

      {/* Recent Post */}
      {/* <BlogCard  /> */}

      {/* comment section */}
      <div className="comment-heading">
        <div className="comments-and-view">
          <p>Comments ({blogData.commentsCount})</p>
          <Link style={{ color: "#3FA959", textDecoration: "underline" }}>
            view all
          </Link>
        </div>

        <div className="write-comment">
          <textarea placeholder="write your comment here..."></textarea>
          <button onClick={hanldeCommentClick} className="add-comment">Add comment</button>
        </div>

        <div className="horizontal-line comment-hr-line"></div>

        {blogData.reviews.map((comment) => {
          return (
            <div key={comment.id} className="comment-section">
              <div className="name-and-time">
                <h6>{comment.userName}</h6>
                <p>{comment.date}</p>
              </div>
              <p className="comment-para-data">{comment.comment}</p>
            </div>
          );
        })}
      </div>

      <div className="horizontal-line  comment-hr-line"></div>
    </div>
  );
};

export default BlogDetailsPage;


