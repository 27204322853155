import Chip from "@mui/material/Chip";
import React, { useState } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { TextField, Button, Paper } from "@mui/material";
import "./CSS/Button.css";
import { Link, useNavigate } from "react-router-dom";

export function SelectButton(props) {
  let link = props.link;
  return (
    <div>
      <Chip
        color={props.color}
        value={link}
        label={link}
        variant="outlined"
        onClick={() => props.handleClick(link)}
      />
    </div>
  );
}

export function SearchButton(props) {
  const navigate = useNavigate();

  const searchSubmit = (e) => {
    e.preventDefault();
  };

  const handleRegister = () => {
    if(props.searchText == "") return;
    navigate("/register");
  };

  return (
    <form className="search-btn" onSubmit={(event) => searchSubmit(event)}>
      <input
        style={{ fontSize: "20px", border: "none" }}
        type="search"
        placeholder="Enter your email"
        onChange={(event) => props.setSearchText(event.target.value)}
        value={props.searchText}
      />
      <button type="submit" onClick={handleRegister}>
        Register
      </button>
    </form>
  );
}

export function ChatBar(props) {
  return (
    <div style={{ display: "flex" }}>
      <TextField
        fullWidth
        label="Message"
        value={props.inputText}
        onChange={(e) => props.setinputText(e.target.value)}
        variant="outlined"
        margin="normal"
      />
      <Button
        sx={{ margin: ".7em" }}
        variant="contained"
        color="primary"
        onClick={() => props.handleSendMessage(10)}
      >
        Send
      </Button>
    </div>
  );
}

export function TopicsListBar(props) {
  return (
    <div>
      <Paper className="topics" style={{ padding: "1em" }}>
        {props.topicslist.map((topic) => (
          <div
            key={topic}
            className="topic"
            onClick={() => props.setselectedTopic(topic)}
            style={{ color: topic === props.selectedTopic ? "green" : "black" }}
          >
            {topic}{" "}
          </div>
        ))}
      </Paper>
    </div>
  );
}

export function ClickableButton(props) {
  return (
    <div>
      <button
        className="clickable-btn"
        onClick={() => console.log("clicked ")}
        style={{ backgroundColor: props.color }}
      >
        {" "}
        {props.name}
      </button>
    </div>
  );
}

export function ViewAllCourses() {
  return (
    <div className="all-courses-btn">
      <Link to={"/programs"}>
        <button>View All Courses</button>
      </Link>
    </div>
  );
}

export function ViewAllBlogs() {
  return (
    <div className="all-blogs-btn">
      <Link style={{ color: "white" }} to="/blogs">
        More Blogs
      </Link>
    </div>
  );
}

export function JoinButton() {
  return (
    <div className="green">
      <Link style={{ cursor: "pointer" }} to={"/login"}>
        <button>Join</button>
      </Link>
    </div>
  );
}
