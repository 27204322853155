import React, { useEffect, useState } from "react";
import JobApplyCard from "../../Molecule/JobApplyCard";
import "../../Molecule/CSS/JobApplyCard.css";
import * as JobOpenningsService from "../../service/JobsApiService";

export default function Careers() {
  const [jobOpennings, setJobOppenings] = useState([
    {
      id: 1,
      title: "Front-end Engineer",
      description:
        "As candidates embark on their journey with us, our AI system meticulously analyzes every facet of their professional profile. From resumes and cover letters to online portfolios and social media presence, the system generates a comprehensive digital footprint that captures a candidate's skills, experiences, and unique attributes. This comprehensive overview enables recruiters to make informed decisions based on a wealth of information, far surpassing the limitations of a static CV review.",
      requirements: ["React", "Angular"],
      experience: "1-6 years",
    },
  ]);

  useEffect(() => {
    JobOpenningsService.getAllJobOpennings().then((opennings) => {
      setJobOppenings(opennings);
    });
  }, []);

  return (
    <div
      style={{
        background: "black",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        fontFamily: "'Raleway', sans-serif",
        overflow: "hidden",
      }}
    >
      <div
        className="career-head"
        style={{ margin: "4rem 0", position: "relative" }}
      >
        <h2 style={{ fontSize: "1.9rem" }}>Careers</h2>
        <p className="career-head-para">
          Explore and apply for the exponential <br /> opportunities at
          byteCoder
        </p>

        <div className="lamp">
          <img src="/images/lamp.svg" alt="" />
        </div>
      </div>

      <div className="cmpny-img-and-job">
        {/* Company Images */}
        <div className="company-images">
          <div className="first-images-container">
            <div className="first-image">
              <img src="/images/first-image.svg" alt="" />
            </div>
            <div className="first-images">
              <img
                className="first-one"
                style={{
                  objectFit: "cover",
                }}
                src="/images/second-image.svg"
                alt=""
              />
              <img className="first-two" src="/images/third-image.svg" alt="" />
            </div>
          </div>

          <div className="second-images-container">
            <div className="second-image">
              <img src="/images/working-emp.svg" alt="" />
            </div>
            <div className="second-images">
              <img
                className="second-images-1"
                src="/images/right-image.svg"
                alt=""
              />
              <img
                className="second-images-2"
                src="/images/right-image-2.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="job-card" style={{ position: "relative" }}>
          <h5 style={{ textAlign: "start", fontSize: "16px" }}>
            We are Hiring for the below Roles:
          </h5>

          <div className="brain">
            <img src="/images/brain.svg" alt="" />
          </div>

          <div className="pen">
            <img src="/images/pen.svg" alt="" />
          </div>
          <div className="mail-envelope">
            <img src="/images/envelope.svg" alt="" />
          </div>

          <div className="notes">
            <img src="/images/notes.svg" alt="" />
          </div>

          {jobOpennings.map((jobDetails) => (
            <JobApplyCard
              title={jobDetails.title}
              requirements={jobDetails.requirements}
              experience={jobDetails.experience}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
