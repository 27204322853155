import React, { useEffect, useState } from "react";
import * as BlogsService from "../../service/BlogsApi";
import { BlogCard } from "../../Molecule/BlogsCard";
import { SelectButton } from "../../Atoms/Buttons";
import './BlogCardDetails.css'
import SearchAndPopularTags from '../../Atoms/SerachAndPopularTags'
const BlogsCardDetails = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [populerShortcuts, setpopulerShortcuts] = useState([])
  const [interest, setInterest] = useState("")


  useEffect(() => {
    BlogsService.getWebsiteBlogs("","").then((response) =>
      setBlogs(response.response)
    );
    BlogsService.getPopulerShortcuts().then(shortcuts=> setpopulerShortcuts(shortcuts))

  }, []);

  useEffect(() => {
    BlogsService.getWebsiteBlogs("",interest).then((response) =>
      setBlogs(response.response)
    );

  }, [interest]);
  
  const searchBlogs= (e)=>{
    e.preventDefault()
  console.log(searchText)
  BlogsService.getWebsiteBlogs(searchText, interest ).then((response) =>
      setBlogs(response.response)
    );
  }

  return (
    <div className="blog-area">
      <div>
        <h1>Our Blogs</h1>
      </div>

      <div className="blog-desc">
        <p style={{}}>
          Elevating Your Journey from Education <br />
          to Employment
        </p>
      </div>

      <div className="globe">
        <img src="/images/globe2.svg" alt="" />
      </div>

     
      <SearchAndPopularTags props = {{interest, populerShortcuts,setInterest,searchText,setSearchText, searchEvent: searchBlogs} } />

      <div className="blog-cards-container">
        {blogs.map((blog) => (
          <BlogCard blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogsCardDetails;
