import React from "react"
import { SelectButton } from "./Buttons"

export default function SearchAndPopularTags(props){
    props = props.props
    return(<div>
                <form onSubmit={(event)=> props.searchEvent(event)}>
          <button type="submit" className="submitButton">
            Search
          </button>
          <input
            type="search"
            placeholder="Search anything suitable ..."
            onChange={(event) => props.setSearchText(event.target.value)}
            value={props.searchText}
          />
        </form>
        <div className="Shortcuts" style={{ display: "flex" }}>
          Populer:{" "}
          {props.populerShortcuts.map((text) => (
            <div className="select-shortcut">
              <SelectButton
                className="selectedButton"
                link={text}
                handleClick={(value) => props.interest === value ?  props.setInterest("") : props.setInterest(value)}
                color ={props.interest === text ?"secondary": "primary"}
              ></SelectButton>
            </div>
          ))}
        </div>
    </div>)
}