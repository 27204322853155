import React from "react";
import { ClickableButton } from "../Atoms/Buttons";

export default function JobApplyCard(props) {
  const { requirements, experience, title } = props;

  return (
    <div className="jobCard" style={{ border: "1px solid gray" }}>
      <div className="single-job-card">
        <div className="jobs-requirements">
          <div className="job-title">
            <h6 style={{ fontSize: "0.9rem", margin: 0, textAlign: "start" }}>
              {title}
            </h6>
          </div>

          <ul>
            <li>Required Technology: {requirements.join("/").split("")}</li>
            <li>Required Experience: {experience}</li>

            {/* {props.jobDetails.map((jobs) => (
              
            ))} */}
          </ul>
        </div>

        <div className="view-apply-btn">
          <ClickableButton name="View Details" color="grey" />
          <ClickableButton name="Apply" color="#3FA959" />
        </div>
      </div>
    </div>
  );
}
