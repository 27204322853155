import React from "react";
import { SERVICE_CARD_DETAILS } from "../Constants/Constants";
import ServiceCardDetails from "./ServiceCardDetails";
import "./ServiceCardDetails.css";

export default function Services() {
  return (
    <div
    className="single-cards-container"
      style={{
      //     border: " 3px solid",
        //   height: "60vh",
        width:"100vw",
        position:"relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFF4F0",
      }}
    >
      <div style={{ position: "absolute" , top: "200px", left:"30px"}} className="globe">
        <img src="/images/globe.svg" alt="" />
      </div>
      <div className="services-card">
        {SERVICE_CARD_DETAILS.map((serviceDetail) => (
          <ServiceCardDetails serviceDetail={serviceDetail} />
        ))}
      </div>

      <div style={{ position: "absolute" , right: 0, bottom:0}} className="globe1">
        <img src="/images/globe2.svg" alt="" />
      </div>
      
     
    </div>
  );
}
