import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function CourseCard(props) {
  const [mediaType, setMediaType] = useState({imageUrl:""});

  useEffect(() => {
    // const isImage = isImageType();

    // setMediaType(isImage ? 'image' : 'video')
  }, [])
  const course = props.course;

  // checking what we have image or video
  // const isImageType = () => {
  //   const imgaeExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];

  //   return imgaeExtensions.some((extension) =>
  //     course.imageUrl.endsWith(extension)
  //   );
  // }
  return (
    <div
      className="course-container"
      style={{
        // border: "1px solid",
        // height: "45vh",
        // width: "230px",
        // // marginBottom: "1rem",
        // borderRadius: "20px",
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "center",
        // // border:"2px solid"
      }}
    >
      <div
        style={{
          width: "90%",
          height: "90%",
          // display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          // border:"2px solid"
        }}
        key={course.id}
        onClick={() => props.openCourse(course)}
      >
        <div className="course-img" style={{ width: "100%" }}>

         <div className="program-img"
                 style={{
                objectFit: "cover",
                width: "100%",
                height: "175px",
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: "20px",
                backgroundColor:'#F4F7F2',
                overflow:'hidden'
                // border: '1px solid'

              }}
            >
            <img src={course.imageUrl} alt="/images/temp/default.png"  />
  
          </div>
        </div>
        {/* <p>Instructor: {course.instructor}</p>
              <p>{course.description}</p> */}
        <div
          className="course-name-details"
          style={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "start",
            width: "100%",

            // border: "2px solid",
            // justifyContent:"space-between"
          }}
        >
          <h3 style={{ fontWeight: "bold", fontSize: "16px", margin: 0 }}>
            {course.title}
          </h3>
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              // textAlign:"start"
              // border:"2px solid"
              color: "#817777",
            }}
            className="course-price-and-time"
          >
            <p>{course.duration}</p>
            <p>{course.price}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
